<template>
    <div class="md-layout-item">
        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-primary">
                <div class="card-icon">
                    <md-icon>person</md-icon>
                </div>
                <h4 class="title">{{ trans('app.user') }}</h4>
            </md-card-header>
            <md-card-content>
                <form class="md-layout" v-on:submit.prevent>
                    <div class="md-layout-item md-size-100">
                        <md-field :class="user.getFieldClass('name')">
                            <label for="name">{{ trans('fields.name') }}</label>
                            <md-input id="name" v-model="user.name"/>
                            <has-error :form="user" field="name"/>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <md-field :class="user.getFieldClass('email')">
                            <label for="email">{{ trans('fields.email') }}</label>
                            <md-input type="email" id="email" v-model="user.email"/>
                            <has-error :form="user" field="email"/>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <md-field :class="user.getFieldClass('role_id')">
                            <label for="role_id">{{ trans('fields.role_id') }}</label>
                            <md-select id='role_id' v-model='user.role_id'style="overflow: hidden;">
                                <md-option v-for="(role, index) in roles" :key='index' :value='role.id' >{{ role.description }}</md-option>
                            </md-select>
                            <has-error :form="user" field="role_id"/>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-50 md-small-size-100">
                        <md-field :class="user.getFieldClass('password')">
                            <label for="password">{{ trans('fields.password') }}</label>
                            <md-input type="password" id="password" v-model="user.password"/>
                            <has-error :form="user" field="password"/>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-50 md-small-size-100">
                        <md-field :class="user.getFieldClass('password_confirmation')">
                            <label for="password_confirmation">{{ trans('fields.password_confirmation') }}</label>
                            <md-input type="password" id="password_confirmation" v-model="user.password_confirmation"/>
                            <has-error :form="user" field="password_confirmation"/>
                        </md-field>
                    </div>

                    <div v-if="user.role && user.role.name != 'admin'" class="md-layout-item md-size-100">
                        <div class="card-title">
                            {{ trans('app.apps') }}:
                        </div>
                        <div class="md-layout">
                            <md-chips md-static
                                v-model="tagApps"
                                class="md-primary"
                                style="width: auto;"
                            ></md-chips>
                            <md-button class="md-icon-button md-dense md-primary" id="btnAddApp" :title="trans('common.add')" @click="openAppsModal">
                                <md-icon>add</md-icon>
                            </md-button>
                        </div>
                    </div>

                    <div class="md-layout-item md-size-100 text-right">
                        <md-button class="md-primary" @click="add()" :disabled="user.loading" id="btnSave">
                            <span v-show="user.loading">{{ trans('common.saving') }}</span>
                            <span v-show="!user.loading">{{ trans('common.save') }}</span>
                        </md-button>
                    </div>
                </form>
            </md-card-content>
        </md-card>

        <md-dialog :md-active.sync="showAppsModal">
            <md-dialog-title>{{ trans('app.apps') }}</md-dialog-title>
            <md-dialog-content md-scrollbar>
                <div class="md-layout">
                    <div class="md-layout-item md-small-size-100 md-size-100">
                        <md-table v-model="apps.data" class="paginated-table table-striped table-hover" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort">

                            <md-table-toolbar>
                                <md-field md-clearable class="md-toolbar-section-start">
                                    <md-input id="txt_search" name="txt_search" :placeholder="trans('common.txt_search')" v-model="txt_search" @input="fetchApps()">
                                    </md-input>
                                </md-field>
                            </md-table-toolbar>

                            <md-table-empty-state v-if="!this.loading"
                                :md-label="trans('app.no_results', {item: trans('app.apps')})">
                            </md-table-empty-state>

                            <md-table-row slot="md-table-row" slot-scope="{ item, index }">
                                <md-table-cell :md-label="trans('app.app')" md-sort-by="name">
                                    <md-checkbox v-model="user.selected_apps" :value="item.id" @change="refreshApps">
                                        {{ item.name }}
                                    </md-checkbox>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                    </div>

                    <div class="mx-auto" style="margin-top: 18px;">
                        <pagination ref="pag" :data="apps" :limit=10 @pagination-change-page="fetchApps"></pagination>
                    </div>
                </div>
            </md-dialog-content>
            <md-dialog-actions>
                <md-button @click="showAppsModal = false" class="md-primary">{{trans('common.continue')}}</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>
<script>
    import { api } from "../../../config";
    import Form from "../../../plugins/Form/Form";

    export default {
        mounted() {
            this.fetchData();
            this.fetchApps();
        },

        data() {
            return {
                requiredFields: [
                    "name",
                    "email",
                    "role_id",
                    "password"
                ],
                user: new Form(),
                roles: [],
                projetId: -1,
                showAppsModal: false,
                currentSort: 'name',
                currentSortOrder: 'asc',
                loading: false,
                txt_search: "",
                allApps: [],
                apps: {
                    current_page:1,
                    data:[]
                },
            };
        },

        computed: {
            tagApps: {
                get() {
                    return this.user.apps.map(function(app) {
                        return app.name;
                    });
                },
                set(tags) {
                    return tags;
                }
            }
        },

        methods: {
            fetchData() {
                var url = api.domain + "user/";
                if (this.$route.params.id) {
                    url += this.$route.params.id + "/edit";
                    this.requiredFields = [
                        "name",
                        "email",
                        "role_id"
                    ];
                } else {
                    url += "create";
                    this.requiredFields = [
                        "name",
                        "email",
                        "role_id",
                        "password"
                    ];
                }
                axios.get(url).then(response => {
                    this.user = new Form(response.data.user);
                    this.user.requiredFields = this.requiredFields;
                    this.roles = response.data.roles;
                    var roles = this.roles.filter(item=> item.name === 'app');
                    if (roles.length > 0) {
                        this.projetId = roles[0].id;
                    }
                }) .catch(function(error) {
                    console.log("An error occured obtaining user info" + error);
                });
            },

            async fetchApps(page=1){
                this.loading = true;

                axios.all([
                  axios.get(api.domain + 'apps'),
                  axios.post(api.domain + 'apps?page='+page, {txt_search: this.txt_search, sort_field: this.currentSort, order: this.currentSortOrder})
                ])
                .then(axios.spread((all, response) => {
                    this.allApps = all.data;
                    this.apps = response.data;
                    this.loading = false;
                }))
                .catch((error) => {
                    console.log('An error ocurred ' + error);
                    this.loading = false;
                });
            },

            async add() {
                var response;

                try {
                    if (this.user.id != null && this.user.id != '') {
                        // Edition
                        response = await this.user.put(api.domain + 'user/'+this.user.id);
                    } else {
                        // Creation
                        response = await this.user.post(api.domain + 'user');
                    }

                    if (response.data.success) {
                        this.$noty.success(this.trans('common.success.saving'));
                        this.$router.push({ name: 'users'});
                    } else {
                        this.$noty.error(response.data.msge);
                    }
                } catch(err) {
                    if (err.response && err.response.data.error) {
                        this.$noty.error(this.trans('common.'+err.response.data.error));
                    } else {
                        console.log('an error occured ' + err);
                        this.$noty.error(this.trans('common.error.saving'));
                    }
                }
            },

            refreshApps: function(selected) {
                var _this = this;
                this.user.apps = [];
                selected.forEach( function(appId) {
                    var app = _this.allApps.filter(item=> item.id == appId).shift();
                    _this.user.apps.push(app);
                });
            },

            openAppsModal: function() {
                this.showAppsModal = true;
            },

            customSort: function customSort(value) {
                this.fetchApps();

                return value;
            }
        }
    };
</script>