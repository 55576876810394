
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

require('bootstrap-vue');

import Vue from 'vue';
import axios from 'axios';
import VueRouter from 'vue-router';
import VueNoty from 'vuejs-noty';

import router from './router';
import store from './store/index';

import App from './components/App.vue';

// Plugins
import GlobalComponents from './globalComponents';
import GlobalDirectives from './globalDirectives';

// MaterialDashboard plugin
import MaterialDashboard from './material-dashboard';

import Chartist from 'chartist';

// Bootstrap vue
import BootstrapVue from 'bootstrap-vue';

// Vue icons
import feather from 'vue-icon';
Vue.use(feather, 'v-icon');

// Form custom plugin
//import Form from './plugins/Form';
//Vue.use(Form);
import FFMform from './plugins/Form';
Vue.use(FFMform);

Vue.use(VueRouter);
Vue.use(MaterialDashboard);
Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
Vue.use(BootstrapVue);
Vue.use(VueNoty, {
	progressBar: false,
	layout: 'center', //top, topLeft, topCenter, topRight, center, centerLeft, centerRight, bottom, bottomLeft, bottomCenter, bottomRight
	theme: 'bootstrap-v4',
	timeout: 3000 //Set 'false' for sticky notifications
});

// For axios errors
window.axios = axios;

axios.interceptors.request.use(config => {
	config.headers['X-CSRF-TOKEN'] = window.Laravel.csrfToken;
	config.headers['X-Requested-With'] = 'XMLHttpRequest';

	var jwtToken = store.getters.getToken;
	if (jwtToken) {
		config.headers['Authorization'] = 'Bearer ' + jwtToken;
	}

	return config;
}, error => {
	return Promise.reject(error);
});

axios.interceptors.response.use(response => {
	return response;
}, error => {
	//let errorResponseData = error.response.data;
	//const errors = ["token_invalid", "token_expired", "token_not_provided"];
	if (error.response.status==401 /*|| (errorResponseData.error && errors.includes(errorResponseData.error))*/) {
		store.dispatch('unsetAuthUser')
			.then(() => {
				router.push({name: 'login'});
			});
	}

	return Promise.reject(error);
});

// For using same syntax in blade and vue for localization
import _ from 'lodash';
Vue.prototype.trans = (string, args) => {
    let value = _.get(window.i18n, string);

    _.eachRight(args, (paramVal, paramKey) => {
        value = _.replace(value, ':' + paramKey, paramVal);
    });
    return value;
};

// global graphics library setup
Object.defineProperty(Vue.prototype, '$Chartist', {
	get () {
		return this.$root.Chartist;
	}
});


// Date formatters
import moment from 'moment'
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment.utc(String(value), 'YYYY-MM-DD HH:mm').local().format('DD/MM/YYYY HH:mm')
  }
});

Vue.filter('formatDateNoTime', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
});

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
Vue.component('pagination', require('laravel-vue-pagination'));

Vue.component('autocomplete', require('./components/common/AutocompleteComponent.vue'));
Vue.component('app', require('./components/App.vue'));

/**
 * Workaround for vue-router v3.0.2
 */
 /*
Vue.component('router-link', Vue.options.components.RouterLink);
Vue.component('router-view', Vue.options.components.RouterView);
*/

/*
const app = new Vue({
    el: '#app',
    components: { App },
    router,
    store
});
*/

/* eslint-disable no-new */
const app = new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store,
  data: {
    Chartist: Chartist
  }
});
