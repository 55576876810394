<template>
    <div class="md-layout-item">
        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-primary">
                <div class="card-icon">
                    <md-icon>comment_bank</md-icon>
                </div>
                <h4 class="title">{{ trans('app.topic') }}</h4>
            </md-card-header>
            <md-card-content>
                <form class="md-layout" v-on:submit.prevent>
                    <div class="md-layout-item md-size-100">
                        <md-field :class="topic.getFieldClass('name')">
                            <label for="name">{{ trans('fields.name') }}</label>
                            <md-input id="name" v-model="topic.name"/>
                            <has-error :form="topic" field="name"/>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <md-field :class="topic.getFieldClass('android_topic')">
                            <label for="android_topic">{{ trans('fields.android_topic') }}</label>
                            <md-input id="android_topic" v-model="topic.android_topic"/>
                            <has-error :form="topic" field="android_topic"/>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <md-field :class="topic.getFieldClass('ios_topic')">
                            <label for="ios_topic">{{ trans('fields.ios_topic') }}</label>
                            <md-input id="ios_topic" v-model="topic.ios_topic"/>
                            <has-error :form="topic" field="ios_topic"/>
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100 text-right">
                        <md-button class="md-primary" @click="add()" :disabled="topic.loading" id="btnSave">
                            <span v-show="topic.loading">{{ trans('common.saving') }}</span>
                            <span v-show="!topic.loading">{{ trans('common.save') }}</span>
                        </md-button>
                    </div>
                </form>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
    import { api } from "../../../../config";
    import { mapActions } from "vuex";
    import Form from "../../../../plugins/Form/Form";

    export default {
        created() {
            this.fetchData();
        },

        data() {
            return {
                requiredFields: [
                    "name",
                    "android_topic",
                    "ios_topic",
                ],
                topic: new Form(),
                currentSort: 'name',
                currentSortOrder: 'asc',
                loading: false,
                txt_search: "",
            };
        },

        methods: {
            ...mapActions([
                'setApp',
            ]),

            fetchData() {
                var url = api.domain;
                if (this.$route.params.id) {
                    url += "topic/" + this.$route.params.id + "/edit";
                } else {
                    url += "app/"+ this.$route.params.appId +"/topic/create";
                }
                axios.get(url).then(response => {
                    this.topic = new Form(response.data.topic);
                    this.topic.requiredFields = this.requiredFields;
                    this.setApp(response.data.app);
                }) .catch(function(error) {
                    console.log("An error occured obtaining topic info" + error);
                });
            },

            async add() {
                var response;

                try {
                    if (this.topic.id != null && this.topic.id != '') {
                        // Edition
                        response = await this.topic.put(api.domain + 'topic/'+this.topic.id);
                    } else {
                        // Creation
                        response = await this.topic.post(api.domain + 'app/' + this.$route.params.appId + '/topic');
                    }

                    if (response.data.success) {
                        this.$noty.success(this.trans('common.success.saving'));
                        this.$router.push({ name: 'topics', params: { appId: this.$route.params.appId }});
                    } else {
                        this.$noty.error(response.data.msge);
                    }
                } catch(err) {
                    if (err.response && err.response.data.error) {
                        this.$noty.error(this.trans('common.'+err.response.data.error));
                    } else {
                        console.log('an error occured ' + err);
                        this.$noty.error(this.trans('common.error.saving'));
                    }
                }
            },

            customSort: function customSort(value) {
                this.fetchData();

                return value;
            }
        }
    };
</script>