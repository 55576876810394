import axios from 'axios';
import Errors from './Errors';
import { deepCopy } from './util';

class Form {
    /**
     * Create a new form instance.
     *
     * @param {Object} data
     */
    constructor(data = {}) {
        this.loading = false;
        this.successful = false;
        this.errors = new Errors();
        this.originalData = deepCopy(data);
        this.requiredFields = [];

        Object.assign(this, data);
    }

    /**
     * Fill form data.
     *
     * @param {Object} data
     */
    fill(data) {
        this.keys().forEach(key => {
            this[key] = data[key];
        });
    }

    /**
     * Append data to formData
     * @param {Object} formData: where put data
     * @param {string} key: key in formData
     * @param {Object} data: data for key
     */
    appendData(formData, key, data) {
        if (_.isObject(data) && !(data instanceof File || data instanceof Date)) {
            formData.append(key, JSON.stringify(data));
        } else {
            formData.append(key, data);
        }
    }

    /**
     * Get the form data.
     *
     * @return {Object}
     */
    data() {
        let formData = new FormData();

        this.keys().forEach(key => {
            if (this[key] != null) {
                if (Array.isArray(this[key])) {
                    this[key].forEach((element) => {
                        this.appendData(formData, key+'[]', element);                    
                    });
                } else {
                    this.appendData(formData, key, this[key]);                    
                }
            }
        });

        return formData;
    }

    getFieldClass(field) {
      var fieldClass = '';

      if (this.requiredFields.includes(field)) {
        fieldClass = 'md-required';
      }

      if (this.errors.has(field)) {
        fieldClass += ' md-error md-invalid';
      }

      return fieldClass;
    }

    /**
     * Get the form data keys.
     *
     * @return {Array}
     */
    keys() {
        return Object.keys(this)
            .filter(key => !Form.ignore.includes(key));
    }

    /**
     * Start processing the form.
     */
    startProcessing() {
        this.errors.clear();
        this.loading = true;
        this.successful = false;
    }

    /**
     * Finish processing the form.
     */
    finishProcessing() {
        this.loading = false;
        this.successful = true;
    }

    /**
     * Clear the form errors.
     */
    clear() {
        this.errors.clear();
        this.successful = false;
    }

    /**
     * Reset the form fields.
     */
    reset () {
        Object.keys(this)
            .filter(key => !Form.ignore.includes(key))
            .forEach(key => {
                this[key] = deepCopy(this.originalData[key]);
            });
    }

    /**
     * Submit the form via a GET request.
     *
     * @param  {String} url
     * @param  {Object} config (axios config)
     * @return {Promise}
     */
    get(url, config = {}) {
        return this.submit('get', url, config);
    }

    /**
     * Submit the form via a POST request.
     *
     * @param  {String} url
     * @param  {Object} config (axios config)
     * @return {Promise}
     */
    post(url, config = {}) {
        return this.submit('post', url, config);
    }

    /**
     * Submit the form via a PATCH request.
     *
     * @param  {String} url
     * @param  {Object} config (axios config)
     * @return {Promise}
     */
    patch(url, config = {}) {
        return this.submit('patch', url, config);
    }

    /**
     * Submit the form via a PUT request.
     *
     * @param  {String} url
     * @param  {Object} config (axios config)
     * @return {Promise}
     */
    put(url, config = {}) {
        return this.submit('put', url, config);
    }

    /**
     * Submit the form via a DELETE request.
     *
     * @param  {String} url
     * @param  {Object} config (axios config)
     * @return {Promise}
     */
    delete (url, config = {}) {
        return this.submit('delete', url, config);
    }

    /**
     * Submit the form data via an HTTP request.
     *
     * @param  {String} method (get, post, patch, put)
     * @param  {String} url
     * @param  {Object} config (axios config)
     * @return {Promise}
     */
    submit(method, url, config = {}) {
        this.startProcessing();

        var data = method === 'get'
            ? { params: this.data() }
            : this.data();

        // There is a bug with axios put and multipart forms -> Data is not sent
        if (method == 'put') {
            method = 'post';
            data.append('_method', 'PUT');
        }

        return new Promise((resolve, reject) => {
            axios.request({ url: this.route(url), method, data, ...config })
                .then(response => {
                    this.finishProcessing();

                    resolve(response);
                })
                .catch(error => {
                    this.loading = false;

                    if (error.response) {
                        this.errors.set(this.extractErrors(error.response));
                    }
                    reject(error);
                });
        });
    }

    /**
     * Extract the errors from the response object.
     *
     * @param  {Object} response
     * @return {Object}
     */
    extractErrors(response) {
        if (!response.data || typeof response.data !== 'object') {
            return { error: Form.errorMessage };
        }

        if (response.data.errors) {
            return { ...response.data.errors };
        }

        if (response.data.message) {
            return { error: response.data.message };
        }

        if (response.data.error) {
            return { error: response.data.error };
        }

        return { ...response.data };
    }

    /**
     * Get a named route.
     *
     * @param  {String} name
     * @return {Object} parameters
     * @return {String}
     */
    route(name, parameters = {}) {
        let url = name;

        if (Form.routes.hasOwnProperty(name)) {
            url = decodeURI(Form.routes[name]);
        }

        if (typeof parameters !== 'object') {
            parameters = { id: parameters };
        }

        Object.keys(parameters).forEach(key => {
            url = url.replace(`{${key}}`, parameters[key]);
        })

        return url;
    }

    /**
     * Clear errors on keydown.
     *
     * @param {KeyboardEvent} event
     */
    onKeydown (event) {
        if (event.target.name) {
            this.errors.clear(event.target.name);
        }
    }
}

Form.routes = {};
Form.errorMessage = 'Something went wrong. Please try again.';
Form.ignore = ['loading', 'successful', 'errors', 'originalData', 'requiredFields'];

export default Form;