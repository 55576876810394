import Login from './components/pages/auth/Login.vue';
import Logout from './components/pages/auth/Logout.vue';
import RememberPassword from './components/pages/auth/password/RememberPassword.vue';
import ResetPassword from './components/pages/auth/password/ResetPassword.vue';
import DashboardLayout from './components/pages/Layout/DashboardLayout.vue';
import Dashboard from './components/pages/Dashboard.vue';
import Users from './components/pages/users/List.vue';
import User from './components/pages/users/Form.vue';
import Apps from './components/pages/apps/List.vue';
import App from './components/pages/apps/Form.vue';
import Topics from './components/pages/apps/topics/List.vue';
import Topic from './components/pages/apps/topics/Form.vue';
import Notifications from './components/pages/apps/notifications/List.vue';
import Notification from './components/pages/apps/notifications/Form.vue';
import Logs from './components/pages/apps/logs/List.vue';
import Stats from './components/pages/apps/stat/List.vue';

export default [
	{
		path: '/',
		name: 'index',
		component: DashboardLayout,
    	redirect: '/dashboard',
		meta: {requiresAuth: true},
		children: [
		  {
		    path: 'dashboard',
		    name: 'dashboard',
		    component: Dashboard,
			meta: {requiresAuth: true},
		  },
		  {
		  	path: 'users',
		  	name: 'users',
		  	component: Users,
		  	meta: {requiresAuth: true},
		  },
		  {
		  	path: 'user/:id?',
		  	name: 'user',
		  	component: User,
		  	meta: {requiresAuth: true},
		  },
		  {
		  	path: 'apps',
		  	name: 'apps',
		  	component: Apps,
		  	meta: {requiresAuth: true},
		  },
		  {
		  	path: 'app/:id?',
		  	name: 'app',
		  	component: App,
		  	meta: {requiresAuth: true},
		  },
		  {
		  	path: 'app/:appId/topics',
		  	name: 'topics',
		  	component: Topics,
		  	meta: {requiresAuth: true},
		  },
		  {
		  	path: 'app/:appId/topic/:id?',
		  	name: 'topic',
		  	component: Topic,
		  	meta: {requiresAuth: true},
		  },
		  {
		  	path: 'app/:appId/notifications',
		  	name: 'notifications',
		  	component: Notifications,
		  	meta: {requiresAuth: true},
		  },
		  {
		  	path: 'app/:appId/notification/:id?',
		  	name: 'notification',
		  	component: Notification,
		  	meta: {requiresAuth: true},
		  },
		  {
		  	path: 'app/:appId/logs',
		  	name: 'logs',
		  	component: Logs,
		  	meta: {requiresAuth: true},
		  },
		  {
		  	path: 'app/:appId/stat',
		  	name: 'stats',
		  	component: Stats,
		  	meta: {requiresAuth: true},
		  },
		]		
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: {requiresGuest: true}
	},
	{
		path: '/logout',
		name: 'logout',
		component: Logout,
		meta: {requiresAuth: true}
	},
	{
		path: '/password/reset/:token',
		name: 'reset-password',
		component: ResetPassword,
		meta: {requiresGuest: true}
	},
	{
		path: '/password/reset',
		name: 'forgotten-password',
		component: RememberPassword,
		meta: {requiresGuest: true}
	}
]
