<template>
    <div class="md-layout-item">
        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-primary">
                <div class="card-icon">
                    <md-icon>notifications</md-icon>
                </div>
                <h4 class="title">
                    {{ trans("app.notifications") }}
                    <md-progress-spinner
                        v-if="loading"
                        :md-stroke="1"
                        :md-diameter="20"
                        md-mode="indeterminate"
                    ></md-progress-spinner>
                </h4>
            </md-card-header>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-small-size-100 md-size-100">
                        <md-table
                            v-model="notifications.data"
                            class="paginated-table table-striped table-hover"
                            :md-sort.sync="currentSort"
                            :md-sort-order.sync="currentSortOrder"
                            :md-sort-fn="customSort"
                        >
                            <md-table-toolbar>
                                <div class="md-toolbar-section-start">
                                    <router-link
                                        :to="{
                                            name: 'notification',
                                            params: { appId: appId },
                                        }"
                                    >
                                        <md-button
                                            class="btn btn-primary md-primary"
                                            id="btnAdd"
                                        >
                                            <div class="float-left">
                                                <md-icon>add</md-icon>
                                            </div>
                                            &nbsp; {{ trans("common.add") }}
                                        </md-button>
                                    </router-link>
                                </div>
                                <md-field
                                    md-clearable
                                    class="md-toolbar-section-end"
                                >
                                    <md-input
                                        id="txt_search"
                                        name="txt_search"
                                        :placeholder="
                                            trans('common.txt_search')
                                        "
                                        v-model="txt_search"
                                        @input="fetchNotifications()"
                                    >
                                    </md-input>
                                </md-field>
                            </md-table-toolbar>

                            <md-table-empty-state :md-label="emptyText">
                            </md-table-empty-state>

                            <md-table-row
                                slot="md-table-row"
                                slot-scope="{ item, index }"
                            >
                                <md-table-cell
                                    md-label="ID"
                                    md-sort-by="app_order"
                                >
                                    {{ item.app_order }}
                                </md-table-cell>
                                <md-table-cell
                                    :md-label="trans('fields.title')"
                                    md-sort-by="title"
                                >
                                    {{ item.title }}
                                </md-table-cell>
                                <md-table-cell
                                    :md-label="trans('fields.message')"
                                >
                                    {{
                                        item.message.substring(0, 59) +
                                        (item.message.length > 60 ? "..." : "")
                                    }}
                                </md-table-cell>
                                <md-table-cell
                                    :md-label="trans('fields.type')"
                                    md-sort-by="type"
                                >
                                    {{ item.type }}/{{ item.rtve_id }}
                                </md-table-cell>
                                <!--md-table-cell
                                    :md-label="trans('fields.topic_id')"
                                >
                                    {{ item.topic.name }}
                                </md-table-cell-->
                                <md-table-cell
                                    :md-label="trans('app.sent')"
                                    md-sort-by="sent"
                                >
                                    <span v-if="item.sent">{{
                                        trans("common.yes")
                                    }}</span>
                                    <span v-else>{{ trans("common.no") }}</span>
                                </md-table-cell>
                                <md-table-cell
                                    :md-label="trans('app.last_sent')"
                                    md-sort-by="last_sent"
                                >
                                    {{ item.last_sent | formatDate }}
                                </md-table-cell>
                                <md-table-cell
                                    :md-label="trans('app.programming')"
                                >
                                    <span v-if="item.schedule_type">{{
                                        trans(
                                            "app.schedule_types." +
                                                item.schedule_type
                                        )
                                    }}</span>
                                    <!--span v-else>{{ trans('common.no') }}</span-->
                                </md-table-cell>
                                <md-table-cell
                                    :md-label="trans('common.actions')"
                                >
                                    <b-link
                                        v-if="item.sent"
                                        :title="trans('app.resend')"
                                        @click="
                                            confirmSendNotification(item, index)
                                        "
                                        id="lnkSend"
                                    >
                                        <md-icon class="md-primary">
                                            outgoing_mail
                                        </md-icon>
                                    </b-link>
                                    <b-link
                                        v-else
                                        :title="trans('common.send')"
                                        @click="
                                            confirmSendNotification(item, index)
                                        "
                                        id="lnkSend"
                                    >
                                        <md-icon class="md-primary">
                                            outgoing_mail
                                        </md-icon>
                                    </b-link>

                                    <b-link
                                        :title="trans('app.clone')"
                                        @click="
                                            confirmCloneNotification(
                                                item,
                                                index
                                            )
                                        "
                                        id="lnkClone"
                                    >
                                        <md-icon class="md-primary">
                                            content_copy
                                        </md-icon>
                                    </b-link>
                                    <router-link
                                        :to="{
                                            name: 'notification',
                                            params: {
                                                id: item.id,
                                                appId: appId,
                                            },
                                        }"
                                        :title="trans('common.edit')"
                                        id="lnkEdit"
                                    >
                                        <md-icon class="md-primary"
                                            >edit</md-icon
                                        >
                                    </router-link>
                                    <b-link
                                        :title="trans('common.delete')"
                                        @click="
                                            confirmDeleteNotification(
                                                item,
                                                index
                                            )
                                        "
                                        id="lnkDelete"
                                    >
                                        <md-icon class="md-primary">
                                            delete
                                        </md-icon>
                                    </b-link>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                    </div>

                    <div class="mx-auto" style="margin-top: 18px">
                        <pagination
                            ref="pag"
                            :data="notifications"
                            :limit="10"
                            @pagination-change-page="fetchNotifications"
                        ></pagination>
                    </div>
                </div>
            </md-card-content>
        </md-card>

        <md-dialog-confirm
            :md-active.sync="showConfirmSend"
            :md-title="trans('common.confirmation')"
            :md-content="confirmSendContent"
            :md-confirm-text="trans('common.ok')"
            :md-cancel-text="trans('common.cancel')"
            @md-confirm="sendNotification"
        />

        <md-dialog-confirm
            :md-active.sync="showConfirmClone"
            :md-title="trans('common.confirmation')"
            :md-content="confirmCloneContent"
            :md-confirm-text="trans('common.ok')"
            :md-cancel-text="trans('common.cancel')"
            @md-confirm="cloneNotification"
        />

        <md-dialog-confirm
            :md-active.sync="showConfirmDelete"
            :md-title="trans('common.confirmation')"
            :md-content="confirmDeleteContent"
            :md-confirm-text="trans('common.ok')"
            :md-cancel-text="trans('common.cancel')"
            @md-confirm="deleteNotification"
        />
    </div>
</template>
<script>
import tableEmptyText from "../../../../mixins/tableEmptyText";
import { api } from "../../../../config";
import { mapState, mapActions } from "vuex";

export default {
    mixins: [tableEmptyText],

    data() {
        return {
            appId: this.$route.params.appId,
            notifications: {
                current_page: 1,
                data: [],
            },
            selectedNotification: {},
            selectedIndex: 0,
            txt_search: "",
            showConfirmDelete: false,
            confirmDeleteContent: this.trans(
                "app.confirm.delete_notification",
                { notification: "" }
            ),
            showConfirmClone: false,
            confirmCloneContent: this.trans("app.confirm.clone_notification", {
                notification: "",
            }),
            showConfirmSend: false,
            confirmSendContent: this.trans("app.confirm.send_notification", {
                notification: "",
            }),
            currentSort: "app_order",
            currentSortOrder: "desc",
            emptyText: this.trans("app.no_results", {
                item: this.trans("app.notifications"),
            }),
            loading: false,
        };
    },

    watch: {
        selectedNotification: function (notification) {
            this.confirmCloneContent = this.trans(
                "app.confirm.clone_notification",
                { notification: this.selectedNotification.title }
            );
            this.confirmDeleteContent = this.trans(
                "app.confirm.delete_notification",
                { notification: this.selectedNotification.title }
            );
            this.confirmSendContent = this.trans(
                "app.confirm.send_notification",
                { notification: this.selectedNotification.title }
            );
        },
    },

    methods: {
        ...mapActions(["setApp"]),

        async fetchNotifications(page = 1) {
            this.loading = true;
            axios
                .post(
                    api.domain +
                        "app/" +
                        this.appId +
                        "/notifications?page=" +
                        page,
                    {
                        txt_search: this.txt_search,
                        sort_field: this.currentSort,
                        order: this.currentSortOrder,
                    }
                )
                .then((response) => {
                    this.notifications = response.data.notifications;
                    this.setApp(response.data.app);
                    this.loading = false;
                })
                .catch((error) => {
                    console.log("An error ocurred " + error);
                    this.loading = false;
                });
        },

        clearFilters() {
            this.txt_search = "";
            this.fetchNotifications();
        },

        confirmSendNotification(notification, key) {
            this.selectedNotification = notification;
            this.selectedIndex = key;
            this.showConfirmSend = true;
        },

        async sendNotification() {
            let id = this.selectedNotification.id;
            let key = this.selectedIndex;
            let uri = api.domain + "notification/" + id + "/resend";
            this.loading = true;

            axios
                .post(uri)
                .then(async (response) => {
                    if (response.data.success) {
                        this.notifications.data[key] =
                            response.data.notification;
                        this.$noty.success(
                            this.trans("app.success.send_notif")
                        );
                    } else {
                        this.$noty.error(this.trans("app.error.send_notif"));
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    // handle error
                    this.loading = false;
                    console.error(error);
                    this.$noty.error(this.trans("app.error.send_notif"));
                });
        },

        confirmCloneNotification(notification, key) {
            this.selectedNotification = notification;
            this.selectedIndex = key;
            this.showConfirmClone = true;
        },

        async cloneNotification() {
            let id = this.selectedNotification.id;
            let key = this.selectedIndex;
            let uri = api.domain + "notification/" + id + "/clone";
            this.loading = true;
            axios
                .get(uri)
                .then(async (response) => {
                    if (response.data.success) {
                        //this.notifications.data.push(response.data.notification);
                        await this.fetchNotifications();
                    } else {
                        this.$noty.error(
                            this.trans("app.error.clone_notification")
                        );
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    // handle error
                    this.loading = false;
                    console.error(error);
                    this.$noty.error(
                        this.trans("app.error.clone_notification")
                    );
                });
        },

        confirmDeleteNotification(notification, key) {
            this.selectedNotification = notification;
            this.selectedIndex = key;
            this.showConfirmDelete = true;
        },

        deleteNotification() {
            let id = this.selectedNotification.id;
            let key = this.selectedIndex;
            let uri = api.domain + "notification/" + id;
            this.loading = true;
            axios
                .delete(uri)
                .then((response) => {
                    this.notifications.data.splice(key, 1);
                    if (this.notifications.data.length == 0) {
                        this.fetchNotifications(
                            this.$refs.pag.data.current_page - 1
                        );
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    // handle error
                    this.loading = false;
                    if (error.response.status == 400) {
                        console.log(
                            "an error occured " + error.response.data.message
                        );
                    } else {
                        console.log("an error occured " + error);
                    }
                });
        },

        customSort: function customSort(value) {
            this.fetchNotifications();

            return value;
        },
    },
};
</script>
