<template>
    <div class="md-layout-item">
        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-primary">
                <div class="card-icon">
                    <md-icon>comment_bank</md-icon>
                </div>
                <h4 class="title">
                    {{ trans('app.topics') }}
                    <md-progress-spinner v-if="loading" :md-stroke="1" :md-diameter="20" md-mode="indeterminate"></md-progress-spinner>
                </h4>
            </md-card-header>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-small-size-100 md-size-100">
                        <md-table v-model="topics.data" class="paginated-table table-striped table-hover" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort">

                            <md-table-toolbar>
                                <div class="md-toolbar-section-start">
                                    <router-link :to="{name: 'topic', params: { appId: appId }}">
                                        <md-button class="btn btn-primary md-primary" id="btnAdd">
                                            <div class="float-left" >
                                                <md-icon>add</md-icon>
                                            </div>
                                            &nbsp; {{ trans('common.add') }}
                                        </md-button>
                                    </router-link>
                                </div>
                                <md-field md-clearable class="md-toolbar-section-end">
                                    <md-input id="txt_search" name="txt_search" :placeholder="trans('common.txt_search')" v-model="txt_search" @input="fetchTopics()">
                                    </md-input>
                                </md-field>
                            </md-table-toolbar>

                            <md-table-empty-state
                                :md-label="emptyText">
                            </md-table-empty-state>

                            <md-table-row slot="md-table-row" slot-scope="{ item, index }">
                                <md-table-cell :md-label="trans('fields.name')" md-sort-by="name">{{ item.name }}</md-table-cell>
                                <md-table-cell :md-label="trans('fields.android_topic')" md-sort-by="android_topic">{{ item.android_topic }}</md-table-cell>
                                <md-table-cell :md-label="trans('fields.ios_topic')" md-sort-by="ios_topic">{{ item.ios_topic }}</md-table-cell>
                                <md-table-cell :md-label="trans('common.actions')">
                                    <router-link :to="{name: 'topic', params: { id: item.id, appId: appId }}" :title="trans('common.edit')" id="lnkEdit">
                                        <md-icon class="md-primary">edit</md-icon>
                                    </router-link>
                                    <b-link :title="trans('common.delete')" @click="confirmDeleteTopic(item, index)" id="lnkDelete">
                                        <md-icon class="md-primary">delete</md-icon>
                                    </b-link>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                    </div>

                    <div class="mx-auto" style="margin-top: 18px;">
                        <pagination ref="pag" :data="topics" :limit=10 @pagination-change-page="fetchTopics"></pagination>
                    </div>
                </div>
            </md-card-content>
        </md-card>

        <md-dialog-confirm
            :md-active.sync="showConfirmDelete"
            :md-title="trans('common.confirmation')"
            :md-content="confirmDeleteContent"
            :md-confirm-text="trans('common.ok')"
            :md-cancel-text="trans('common.cancel')"
            @md-confirm="deleteTopic" />
    </div>
</template>
<script>
import tableEmptyText from "../../../../mixins/tableEmptyText";
import { api } from "../../../../config";
import { mapState, mapActions } from 'vuex';

export default {
    mixins: [
        tableEmptyText,
    ],

    data() {
        return {
            appId: this.$route.params.appId,
            topics: {
                current_page:1,
                data:[]
            },
            selectedTopic: {},
            selectedIndex: 0,
            txt_search: "",
            showConfirmDelete: false,
            confirmDeleteContent: this.trans('app.confirm.delete_topic', {topic: ''}),
            currentSort: 'name',
            currentSortOrder: 'asc',
            emptyText: this.trans('app.no_results', {item: this.trans('app.topics')}),
            loading: false,
        }
    },

    watch: {
      selectedTopic: function (topic) {
        this.confirmDeleteContent =  this.trans('app.confirm.delete_topic', {topic: this.selectedTopic.name});
      }
    },

    methods: {
        ...mapActions([
            'setApp',
        ]),

        async fetchTopics(page=1){
            this.loading = true;
            axios.post(api.domain + 'app/' + this.appId + '/topics?page='+page, {txt_search: this.txt_search, sort_field: this.currentSort, order: this.currentSortOrder})
            .then((response) => {
                this.topics = response.data.topics;
                this.setApp(response.data.app);
                this.loading = false;
            })
            .catch((error) => {
                console.log('An error ocurred ' + error);
                this.loading = false;
            });
        },

        clearFilters(){
            this.txt_search="";
            this.fetchTopics();
        },

        confirmDeleteTopic(topic, key) {
            this.selectedTopic = topic;
            this.selectedIndex = key;
            this.showConfirmDelete = true;
        },

        deleteTopic() {
            let id = this.selectedTopic.id;
            let key = this.selectedIndex;
            let uri = api.domain + 'topic/' + id;
            this.loading = true;
            axios.delete(uri).then((response) => {
                this.topics.data.splice(key, 1);
                if (this.topics.data.length == 0) {
                    this.fetchTopics(this.$refs.pag.data.current_page - 1);
                }
                this.loading = false;
            }).catch(error => {
                // handle error
                this.loading = false;
                if (error.response.status == 400) {
                    console.log('an error occured ' + error.response.data.message);
                } else {
                    console.log('an error occured ' + error);
                }
            });
        },

        customSort: function customSort(value) {
            this.fetchTopics();

            return value;
        }
    }
}
</script>
