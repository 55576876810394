const apiDomain = Laravel.apiDomain;
export const siteName = Laravel.siteName;
export const rtveImageURL = Laravel.imageUrl;
export const rtveLiveImageURL = Laravel.liveImageUrl;

export const api = {
	domain: apiDomain + '/',
	login: apiDomain + '/auth/login',
	currentUser: apiDomain + '/auth/user',
	remember: apiDomain + '/auth/password/email',
	reset: apiDomain + '/auth/password/reset',
};

export const NotificationType = Object.freeze({
    AUDIO_TYPE: 'audio',
    DIRECTO_TYPE: 'directo',
    DIRECTO_RADIO_TYPE: 'directo_radio',
    NOTICIA_TYPE: 'noticia',
    TEXTO_TYPE: 'texto',
    VIDEO_TYPE: 'video',
});

export const ScheduleType = Object.freeze({
    PUNCTUAL: 'punctual',
    DAILY: 'daily',
    WEEKLY: 'weekly',
    MONTHLY: 'monthly',
});

export const SO = Object.freeze({
    ANDROID: 'Android',
    IOS: 'iOS'
});

export const Openings = Object.freeze({
    TOTAL: 0,
    TO_5: 1,
    FROM_5_15: 2,
    FROM_15_60: 3,
    FROM_60: 4
});