<template>
    <div class="md-layout-item">
        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-primary">
                <div class="card-icon">
                    <md-icon>settings_backup_restore</md-icon>
                </div>
                <h4 class="title">
                    {{ trans("app.logs") }}
                    <md-progress-spinner
                        v-if="loading"
                        :md-stroke="1"
                        :md-diameter="20"
                        md-mode="indeterminate"
                    ></md-progress-spinner>
                </h4>
            </md-card-header>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-small-size-100 md-size-100">
                        <md-table
                            v-model="logs.data"
                            class="paginated-table table-striped table-hover"
                            :md-sort.sync="currentSort"
                            :md-sort-order.sync="currentSortOrder"
                            :md-sort-fn="customSort"
                        >
                            <md-table-toolbar>
                                <div class="md-toolbar-section-start"></div>
                                <md-field
                                    md-clearable
                                    class="md-toolbar-section-end"
                                >
                                    <md-input
                                        id="txt_search"
                                        name="txt_search"
                                        :placeholder="
                                            trans('common.txt_search')
                                        "
                                        v-model="txt_search"
                                        @input="fetchLogs()"
                                    >
                                    </md-input>
                                </md-field>
                            </md-table-toolbar>

                            <md-table-empty-state :md-label="emptyText">
                            </md-table-empty-state>

                            <md-table-row
                                slot="md-table-row"
                                slot-scope="{ item, index }"
                            >
                                <md-table-cell
                                    :md-label="trans('app.datetime')"
                                    md-sort-by="created_at"
                                    >{{
                                        item.created_at | formatDate
                                    }}</md-table-cell
                                >
                                <md-table-cell
                                    :md-label="trans('app.action')"
                                    md-sort-by="action"
                                    >{{
                                        trans("app.action_types." + item.action)
                                    }}</md-table-cell
                                >
                                <md-table-cell
                                    :md-label="trans('app.notification_id')"
                                    >{{ item.notification.title }} ({{
                                        item.notification.app_order
                                    }})</md-table-cell
                                >
                                <md-table-cell
                                    :md-label="trans('fields.email')"
                                    md-sort-by="email"
                                    >{{ item.email }}</md-table-cell
                                >
                            </md-table-row>
                        </md-table>
                    </div>

                    <div class="mx-auto" style="margin-top: 18px">
                        <pagination
                            ref="pag"
                            :data="logs"
                            :limit="10"
                            @pagination-change-page="fetchLogs"
                        ></pagination>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import tableEmptyText from "../../../../mixins/tableEmptyText";
import { api } from "../../../../config";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
    mixins: [tableEmptyText],

    data() {
        return {
            appId: this.$route.params.appId,
            logs: {
                current_page: 1,
                data: [],
            },
            selectedLog: {},
            selectedIndex: 0,
            txt_search: "",
            currentSort: "created_at",
            currentSortOrder: "desc",
            emptyText: this.trans("app.no_results", {
                item: this.trans("app.logs"),
            }),
            loading: false,
        };
    },

    methods: {
        ...mapActions(["setApp"]),

        async fetchLogs(page = 1) {
            this.loading = true;
            axios
                .post(api.domain + "app/" + this.appId + "/logs?page=" + page, {
                    txt_search: this.txt_search,
                    sort_field: this.currentSort,
                    order: this.currentSortOrder,
                })
                .then((response) => {
                    this.logs = response.data.logs;
                    this.setApp(response.data.app);
                    this.loading = false;
                })
                .catch((error) => {
                    console.log("An error ocurred " + error);
                    this.loading = false;
                });
        },

        clearFilters() {
            this.txt_search = "";
            this.fetchLogs();
        },

        customSort: function customSort(value) {
            this.fetchLogs();

            return value;
        },
    },
};
</script>
