<template>
    <div class="md-layout-item">
        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-primary">
                <div class="card-icon">
                    <md-icon>menu_book</md-icon>
                </div>
                <h4 class="title">
                    {{ trans("app.apps") }}
                    <md-progress-spinner
                        v-if="loading"
                        :md-stroke="1"
                        :md-diameter="20"
                        md-mode="indeterminate"
                    ></md-progress-spinner>
                </h4>
            </md-card-header>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-small-size-100 md-size-100">
                        <md-table
                            v-model="apps.data"
                            class="paginated-table table-striped table-hover"
                            :md-sort.sync="currentSort"
                            :md-sort-order.sync="currentSortOrder"
                            :md-sort-fn="customSort"
                        >
                            <md-table-toolbar>
                                <div class="md-toolbar-section-start">
                                    <router-link :to="{ name: 'app' }">
                                        <md-button
                                            class="btn btn-primary md-primary"
                                            id="btnAdd"
                                        >
                                            <div class="float-left">
                                                <md-icon>add</md-icon>
                                            </div>
                                            &nbsp; {{ trans("common.add") }}
                                        </md-button>
                                    </router-link>
                                </div>
                                <md-field
                                    md-clearable
                                    class="md-toolbar-section-end"
                                >
                                    <md-input
                                        id="txt_search"
                                        name="txt_search"
                                        :placeholder="
                                            trans('common.txt_search')
                                        "
                                        v-model="txt_search"
                                        @input="fetchApps()"
                                    >
                                    </md-input>
                                </md-field>
                            </md-table-toolbar>

                            <md-table-empty-state :md-label="emptyText">
                            </md-table-empty-state>

                            <md-table-row
                                slot="md-table-row"
                                slot-scope="{ item, index }"
                            >
                                <md-table-cell
                                    :md-label="trans('fields.code')"
                                    md-sort-by="code"
                                    >{{ item.code }}</md-table-cell
                                >
                                <md-table-cell
                                    :md-label="trans('fields.name')"
                                    md-sort-by="name"
                                    >{{ item.name }}</md-table-cell
                                >
                                <md-table-cell
                                    :md-label="trans('fields.state')"
                                    md-sort-by="state"
                                >
                                    <span v-if="item.state">{{
                                        trans("app.enabled")
                                    }}</span>
                                    <span v-else>{{
                                        trans("app.disabled")
                                    }}</span>
                                </md-table-cell>
                                <md-table-cell
                                    :md-label="trans('fields.apikey')"
                                    >********{{
                                        item.apikey_last_chars
                                    }}</md-table-cell
                                >
                                <md-table-cell
                                    :md-label="trans('fields.firebase_cred')"
                                >
                                    <span v-if="item.firebase_project">
                                        ****{{ item.firebase_project }}****
                                    </span>
                                </md-table-cell>
                                <md-table-cell
                                    :md-label="trans('common.actions')"
                                >
                                    <router-link
                                        :to="{
                                            name: 'topics',
                                            params: { appId: item.id },
                                        }"
                                        :title="trans('app.topics')"
                                        id="lnkTopics"
                                    >
                                        <md-icon class="md-primary"
                                            >comment_bank</md-icon
                                        >
                                    </router-link>
                                    <router-link
                                        :to="{
                                            name: 'app',
                                            params: { id: item.id },
                                        }"
                                        :title="trans('common.edit')"
                                        id="lnkEdit"
                                    >
                                        <md-icon class="md-primary"
                                            >edit</md-icon
                                        >
                                    </router-link>
                                    <b-link
                                        :title="trans('common.delete')"
                                        @click="confirmDeleteApp(item, index)"
                                        id="lnkDelete"
                                    >
                                        <md-icon class="md-primary"
                                            >delete</md-icon
                                        >
                                    </b-link>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                    </div>

                    <div class="mx-auto" style="margin-top: 18px">
                        <pagination
                            ref="pag"
                            :data="apps"
                            :limit="10"
                            @pagination-change-page="fetchApps"
                        ></pagination>
                    </div>
                </div>
            </md-card-content>
        </md-card>

        <md-dialog-confirm
            :md-active.sync="showConfirmDelete"
            :md-title="trans('common.confirmation')"
            :md-content="confirmDeleteContent"
            :md-confirm-text="trans('common.ok')"
            :md-cancel-text="trans('common.cancel')"
            @md-confirm="deleteApp"
        />
    </div>
</template>
<script>
import tableEmptyText from "../../../mixins/tableEmptyText";
import { api } from "../../../config";
import { mapState, mapActions } from "vuex";

export default {
    mixins: [tableEmptyText],

    data() {
        return {
            apps: {
                current_page: 1,
                data: [],
            },
            selectedApp: {},
            selectedIndex: 0,
            txt_search: "",
            showConfirmDelete: false,
            confirmDeleteContent: this.trans("app.confirm.delete_app", {
                app: "",
            }),
            currentSort: "name",
            currentSortOrder: "asc",
            emptyText: this.trans("app.no_results", {
                item: this.trans("app.apps"),
            }),
            loading: false,
        };
    },

    watch: {
        selectedApp: function (app) {
            this.confirmDeleteContent = this.trans("app.confirm.delete_app", {
                app: this.selectedApp.name,
            });
        },
    },

    created: async function () {
        this.unsetApp();
    },

    methods: {
        ...mapActions(["unsetApp"]),

        async fetchApps(page = 1) {
            this.loading = true;
            axios
                .post(api.domain + "apps?page=" + page, {
                    txt_search: this.txt_search,
                    sort_field: this.currentSort,
                    order: this.currentSortOrder,
                })
                .then((response) => {
                    this.apps = response.data;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log("An error ocurred " + error);
                    this.loading = false;
                });
        },

        clearFilters() {
            this.txt_search = "";
            this.fetchApps();
        },

        confirmDeleteApp(app, key) {
            this.selectedApp = app;
            this.selectedIndex = key;
            this.showConfirmDelete = true;
        },

        deleteApp() {
            let id = this.selectedApp.id;
            let key = this.selectedIndex;
            let uri = api.domain + "app/" + id;
            this.loading = true;
            axios
                .delete(uri)
                .then((response) => {
                    this.apps.data.splice(key, 1);
                    if (this.apps.data.length == 0) {
                        this.fetchApps(this.$refs.pag.data.current_page - 1);
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    // handle error
                    this.loading = false;
                    if (error.response.status == 400) {
                        console.log(
                            "an error occured " + error.response.data.message
                        );
                    } else {
                        console.log("an error occured " + error);
                    }
                });
        },

        customSort: function customSort(value) {
            this.fetchApps();

            return value;
        },
    },
};
</script>
