/*
|--------------------------------------------------------------------------
| Mutation Types
|--------------------------------------------------------------------------
*/
export const SET_TOKEN = 'SET_TOKEN';
export const UNSET_TOKEN = 'UNSET_TOKEN';

export const SET_USER = 'SET_USER';
export const UNSET_USER = 'UNSET_USER';

export const SET_APPS = 'SET_APPS';
export const UNSET_APPS = 'UNSET_APPS';

/*
|--------------------------------------------------------------------------
| Initial State
|--------------------------------------------------------------------------
*/
const initialState = {
	jwt_token: null,
	name: null,
	email: null,
	apps: []
};

/*
|--------------------------------------------------------------------------
| Mutations
|--------------------------------------------------------------------------
*/
const mutations = {
	[SET_TOKEN](state, payload) {
		state.jwt_token = payload.access_token;
	},
	[UNSET_TOKEN](state, payload) {
		state.jwt_token = null;
	},

	[SET_USER](state, payload) {
		state.name = payload.user.name;
		state.email = payload.user.email;
		switch (payload.user.role.name) {
			case 'admin':
				state.isAdmin = true;
				state.isEditor = false;
				state.isStat = false;
				break;
			case 'editor':
				state.isEditor = true;
				state.isAdmin = false;
				state.isStat = false;
				break;
			case 'stat':
				state.isStat = true;
				state.isAdmin = false;
				state.isEditor = false;
				break;
			default:
				state.isAdmin = false;
				state.isEditor = false;
				state.isStat = false;
		}
        state.apps = payload.user.apps;
	},
	[UNSET_USER](state, payload) {
		state.name = null;
		state.email = null;
		state.isAdmin = false;
		state.isEditor = false;
		state.isStat = false;
		state.apps = [];
	},

	[SET_APPS](state, payload) {
		state.apps = payload.apps;
	},
	[UNSET_APPS](state, payload) {
		state.apps = [];
	},
};

/*
|--------------------------------------------------------------------------
| Actions
|--------------------------------------------------------------------------
*/
const actions = {
	setToken: (context, access_token) => {
		context.commit(SET_TOKEN, {access_token});
	},
	removeToken: (context) => {
		context.commit(UNSET_TOKEN);
	},

	setAuthUser: (context, user) => {
		context.commit(SET_USER, {user});
	},
	unsetAuthUser: (context) => {
		context.commit(UNSET_TOKEN);
		context.commit(UNSET_USER);
	},

	setApps: (context, apps) => {
		context.commit(SET_APPS, {apps});
	},
	unsetApps: (context) => {
		context.commit(UNSET_APPS);
	},
};

/*
|--------------------------------------------------------------------------
| Getters
|--------------------------------------------------------------------------
*/
const getters = {
	getToken: (state) => {
		return state.jwt_token;
	},

	isLoggedIn: (state) => {
		return !!(state.name && state.email);
	},

    isAdmin: (state) => {
        return state.isAdmin;
    },

    isEditor: (state) => {
        return state.isEditor;
    },

    isStat: (state) => {
        return state.isStat;
    },

    getApps: (state) => {
    	return state.apps;
    }
};

/*
|--------------------------------------------------------------------------
| Export the module
|--------------------------------------------------------------------------
*/
export default {
	state: initialState,
	mutations,
	actions,
	getters
}