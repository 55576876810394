/*
|--------------------------------------------------------------------------
| Mutation Types
|--------------------------------------------------------------------------
*/
export const SET_APP = 'SET_APP';
export const UNSET_APP = 'UNSET_APP';

/*
|--------------------------------------------------------------------------
| Initial State
|--------------------------------------------------------------------------
*/
const initialState = {
	name: null,
};

/*
|--------------------------------------------------------------------------
| Mutations
|--------------------------------------------------------------------------
*/
const mutations = {

	[SET_APP](state, payload) {
		state.name = payload.app.name;
	},
	[UNSET_APP](state, payload) {
		state.name = null;
	}
};

/*
|--------------------------------------------------------------------------
| Actions
|--------------------------------------------------------------------------
*/
const actions = {
	setApp: (context, app) => {
		context.commit(SET_APP, {app});
	},
	unsetApp: (context) => {
		context.commit(UNSET_APP);
	}
};

/*
|--------------------------------------------------------------------------
| Getters
|--------------------------------------------------------------------------
*/
const getters = {
	getAppName: (state) => {
		return state.name;
	},
};

/*
|--------------------------------------------------------------------------
| Export the module
|--------------------------------------------------------------------------
*/
export default {
	state: initialState,
	mutations,
	actions,
	getters
}