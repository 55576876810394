<template>
    <div class="md-layout-item">
        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-primary">
                <div class="card-icon">
                    <md-icon>stacked_bar_chart</md-icon>
                </div>
                <h4 class="title">
                    {{ trans('app.stats') }}
                    <md-progress-spinner v-if="loading" :md-stroke="1" :md-diameter="20" md-mode="indeterminate"></md-progress-spinner>
                </h4>
            </md-card-header>
            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-small-size-100 md-size-100">
                        <md-table v-model="notifications.data" class="paginated-table table-striped table-hover" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort">

                            <md-table-toolbar>
                                <div class="md-toolbar-section-start">
                                    <router-link :to="{name: 'notification', params: { appId: appId }}">
                                        <md-button class="btn btn-primary md-primary" id="btnAdd">
                                            <div class="float-left" >
                                                <md-icon>add</md-icon>
                                            </div>
                                            &nbsp; {{ trans('common.add') }}
                                        </md-button>
                                    </router-link>
                                </div>
                                <md-field md-clearable class="md-toolbar-section-end">
                                    <md-input id="txt_search" name="txt_search" :placeholder="trans('common.txt_search')" v-model="txt_search" @input="fetchNotifications()">
                                    </md-input>
                                </md-field>
                            </md-table-toolbar>

                            <md-table-empty-state
                                :md-label="emptyText">
                            </md-table-empty-state>

                            <md-table-row slot="md-table-row" slot-scope="{ item, index }">
                                <md-table-cell md-label="ID" md-sort-by="app_order">{{ item.app_order }}</md-table-cell>
                                <md-table-cell :md-label="trans('app.last_sent')" md-sort-by="last_sent">{{ item.last_sent | formatDate }}</md-table-cell>
                                <!--md-table-cell :md-label="trans('app.num_sent')" md-sort-by="num_sent">{{ item.num_sent }}</md-table-cell-->
                                <md-table-cell :md-label="trans('fields.title')" md-sort-by="title">{{ item.title }}</md-table-cell>
                                <!--md-table-cell :md-label="trans('fields.message')" md-sort-by="message">{{ item.message }}</md-table-cell-->
                                <md-table-cell :md-label="trans('fields.os')">
                                    {{ ANDROID }} <br/> <br/>
                                    {{ IOS }}
                                </md-table-cell>
                                <md-table-cell :md-label="trans('app.opening_sections.to_5')">
                                    {{ item.stats[ANDROID][SECTION_0_5] }} <br/> <br/>
                                    {{ item.stats[IOS][SECTION_0_5] }}
                                </md-table-cell>
                                <md-table-cell :md-label="trans('app.opening_sections.from_5_15')">
                                    {{ item.stats[ANDROID][SECTION_5_15] }} <br/> <br/>
                                    {{ item.stats[IOS][SECTION_5_15] }}
                                </md-table-cell>
                                <md-table-cell :md-label="trans('app.opening_sections.from_15_60')">
                                    {{ item.stats[ANDROID][SECTION_15_60] }} <br/> <br/>
                                    {{ item.stats[IOS][SECTION_15_60] }}
                                </md-table-cell>
                                <md-table-cell :md-label="trans('app.opening_sections.from_60')">
                                    {{ item.stats[ANDROID][SECTION_60] }} <br/> <br/>
                                    {{ item.stats[IOS][SECTION_60] }}
                                </md-table-cell>
                                <md-table-cell :md-label="trans('app.openings')">
                                    {{ item.stats[ANDROID][SECTION_TOTAL] + item.stats[IOS][SECTION_TOTAL] }}
                                </md-table-cell>
                            </md-table-row>
                        </md-table>
                    </div>

                    <div class="mx-auto" style="margin-top: 18px;">
                        <pagination ref="pag" :data="notifications" :limit=10 @pagination-change-page="fetchNotifications"></pagination>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import tableEmptyText from "../../../../mixins/tableEmptyText";
import { api, SO, Openings } from "../../../../config";
import { mapState, mapActions } from 'vuex';

export default {
    mixins: [
        tableEmptyText,
    ],

    data() {
        return {
            appId: this.$route.params.appId,
            notifications: {
                current_page:1,
                data:[]
            },
            txt_search: "",
            currentSort: 'app_order',
            currentSortOrder: 'desc',
            emptyText: this.trans('app.no_results', {item: this.trans('app.stats')}),
            loading: false,
            ANDROID: SO.ANDROID,
            IOS: SO.IOS,
            SECTION_TOTAL: Openings.TOTAL,
            SECTION_0_5: Openings.TO_5,
            SECTION_5_15: Openings.FROM_5_15,
            SECTION_15_60: Openings.FROM_15_60,
            SECTION_60: Openings.FROM_60,
        }
    },

    methods: {
        ...mapActions([
            'setApp',
        ]),

        async fetchNotifications(page=1) {
            this.loading = true;
            axios.post(api.domain + 'app/' + this.appId + '/stats?page='+page, {txt_search: this.txt_search, sort_field: this.currentSort, order: this.currentSortOrder})
            .then((response) => {
                this.notifications = response.data.notifications;
                this.setApp(response.data.app);
                this.loading = false;
            })
            .catch((error) => {
                console.log('An error ocurred ' + error);
                this.loading = false;
            });
        },

        clearFilters(){
            this.txt_search="";
            this.fetchNotifications();
        },

        customSort: function customSort(value) {
            this.fetchNotifications();

            return value;
        }
    }
}
</script>
