<template>
    <div class="md-layout-item">
        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-primary">
                <div class="card-icon">
                    <md-icon>menu_book</md-icon>
                </div>
                <h4 class="title">{{ trans("app.app") }}</h4>
            </md-card-header>
            <md-card-content>
                <form class="md-layout" v-on:submit.prevent>
                    <div class="md-layout-item md-size-100">
                        <md-checkbox v-model="app.state">{{
                            trans("app.enabled")
                        }}</md-checkbox>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <md-field :class="app.getFieldClass('code')">
                            <label for="code">{{ trans("fields.code") }}</label>
                            <md-input id="code" v-model="app.code" />
                            <has-error :form="app" field="code" />
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <md-field :class="app.getFieldClass('name')">
                            <label for="name">{{ trans("fields.name") }}</label>
                            <md-input id="name" v-model="app.name" />
                            <has-error :form="app" field="name" />
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <md-field :class="app.getFieldClass('apikey')">
                            <label for="apikey">{{
                                trans("fields.apikey")
                            }}</label>
                            <md-input id="apikey" v-model="app.apikey" />
                            <has-error :form="app" field="apikey" />
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <md-field :class="app.getFieldClass('pre_apikey')">
                            <label for="pre_apikey">{{
                                trans("fields.pre_apikey")
                            }}</label>
                            <md-input
                                id="pre_apikey"
                                v-model="app.pre_apikey"
                            />
                            <has-error :form="app" field="pre_apikey" />
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <md-field :class="app.getFieldClass('firebase_cred')">
                            <label for="firebase_cred">{{
                                trans("fields.firebase_cred")
                            }}</label>
                            <md-textarea
                                id="firebase_cred"
                                v-model="app.firebase_cred"
                                md-autogrow
                            />
                            <has-error :form="app" field="firebase_cred" />
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <md-field
                            :class="app.getFieldClass('pre_firebase_cred')"
                        >
                            <label for="pre_firebase_cred">{{
                                trans("fields.pre_firebase_cred")
                            }}</label>
                            <md-textarea
                                id="pre_firebase_cred"
                                v-model="app.pre_firebase_cred"
                                md-autogrow
                            />
                            <has-error :form="app" field="pre_firebase_cred" />
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100">
                        <md-field :class="app.getFieldClass('notes')">
                            <label for="notes">{{
                                trans("fields.notes")
                            }}</label>
                            <md-textarea
                                id="notes"
                                v-model="app.notes"
                                md-autogrow
                            />
                            <has-error :form="app" field="notes" />
                        </md-field>
                    </div>

                    <div class="md-layout-item md-size-100 text-right">
                        <md-button
                            class="md-primary"
                            @click="add()"
                            :disabled="app.loading"
                            id="btnSave"
                        >
                            <span v-show="app.loading">{{
                                trans("common.saving")
                            }}</span>
                            <span v-show="!app.loading">{{
                                trans("common.save")
                            }}</span>
                        </md-button>
                    </div>
                </form>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
import { api } from "../../../config";
import { mapActions } from "vuex";
import Form from "../../../plugins/Form/Form";

export default {
    mounted() {
        this.fetchData();
    },

    data() {
        return {
            requiredFields: [
                "code",
                "name" /*, "apikey", "pre_apikey"*/,
                "firebase_cred",
                "pre_firebase_cred",
            ],
            app: new Form(),
            projetId: -1,
            showProjectsModal: false,
            currentSort: "name",
            currentSortOrder: "asc",
            loading: false,
            txt_search: "",
            allProjects: [],
            projects: {
                current_page: 1,
                data: [],
            },
        };
    },

    methods: {
        ...mapActions(["setApp"]),

        fetchData() {
            var url = api.domain + "app/";
            if (this.$route.params.id) {
                url += this.$route.params.id + "/edit";
            } else {
                url += "create";
            }
            axios
                .get(url)
                .then((response) => {
                    this.app = new Form(response.data.app);
                    this.setApp(this.app);
                    this.app.requiredFields = this.requiredFields;
                    if (!this.app.hasOwnProperty("apikey")) {
                        if (this.app.hasOwnProperty("apikey_last_chars")) {
                            this.app.apikey =
                                "********" + this.app.apikey_last_chars;
                            this.app.pre_apikey =
                                "********" + this.app.pre_apikey_last_chars;
                        }
                    }
                    if (!this.app.hasOwnProperty("firebase_cred")) {
                        if (this.app.firebase_project) {
                            this.app.firebase_cred =
                                "****" + this.app.firebase_project + "****";
                            this.app.pre_firebase_cred =
                                "****" + this.app.pre_firebase_project + "****";
                        }
                    }
                })
                .catch(function (error) {
                    console.log("An error occured obtaining app info" + error);
                });
        },

        async add() {
            var response;

            try {
                if (this.app.id != null && this.app.id != "") {
                    // Edition
                    response = await this.app.put(
                        api.domain + "app/" + this.app.id
                    );
                } else {
                    // Creation
                    response = await this.app.post(api.domain + "app");
                }

                if (response.data.success) {
                    this.$noty.success(this.trans("common.success.saving"));
                    this.$router.push({ name: "apps" });
                } else {
                    this.$noty.error(response.data.msge);
                }
            } catch (err) {
                if (err.response && err.response.data.error) {
                    this.$noty.error(
                        this.trans("common." + err.response.data.error)
                    );
                } else {
                    console.log("an error occured " + err);
                    this.$noty.error(this.trans("common.error.saving"));
                }
            }
        },

        customSort: function customSort(value) {
            this.fetchData();

            return value;
        },
    },
};
</script>
